@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  line-height: 1.5;
  font-family: 'Montserrat,sans-serif,Helvetica Neue,Arial', sans-serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  max-width: 1550px;
  margin: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
ul {
  list-style: none;
}
/* .footer {
  background-color: #24262b;
  padding: 70px 0;
}
.footer-col {
  width: 22%;
  padding: 0 15px;
}
.footer-col h4 {
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}
.footer-col h4::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #ff9d1d;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}
.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footer-col ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
  color: rgb(208, 208, 208);
  display: block;
  transition: all 0.3s ease;
}
.footer-col ul li a:hover {
  color: #ffffff;
  padding-left: 8px;
}
.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: RGB(255, 157, 29, 0.8);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}
.footer-col .social-links a:hover {
  color: #24262b;
  background-color: #ffffff;
}
.logo-Image {
  width: 34%;
}

.logo {
  width: 300px;
}

.columndiv {
  width: 22%;
}

.phargraph {
  padding: 0 10px;
  margin-top: 10px;
  font-size: 16px;
  color: rgb(208, 208, 208);
  font-family: 'Montserrat,sans-serif,Helvetica Neue,Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43rem;
  width: 90%;
  justify-content: center;
  text-align: left;
  letter-spacing: 1px;
}

@media (max-width: 767) {
  .phargraph {
    padding: 0 10px;
    margin-top: 10px;
    font-size: 16px;
    color: rgb(208, 208, 208);
    font-family: 'Montserrat,sans-serif,Helvetica Neue,Arial', sans-serif;
    font-weight: 400;
    line-height: 1.43rem;
    width: 100%;
    justify-content: center;
    text-align: left;
    letter-spacing: 1px;
  }
} */

/* @media (max-width: 767px) {
  .logo-Image {
    width: 100%;
    margin-bottom: 30px;
  }

  .footer {
    background-color: #24262b;
    padding: 70px 0 0 0;
  }
} */

/*responsive*/
/* @media (max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
} */
/* @media (max-width: 574px) {
  .footer-col {
    width: 100%;
  }
} */

.footer {
  background-color: #24262b;
  padding: 50px 10rem;
  font-size: 16px;
}

footer h4 {
  font-size: 20px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
}

.footer-column1 {
  width: 20%;
}

.footer-column2 {
  width: 70%;
}

.logo {
  width: 250px; /* Adjust logo size as needed */
  height: auto;
  margin-bottom: 20px;
  margin-left: -30px;
}

.content-description {
  font-size: 14px; /* Adjust font size as needed */
  color: #fff; /* Set your desired text color */
  font-size: 16px;
}

.social-links {
  margin-top: 20px;
}

.social-links a {
  font-size: 24px; /* Adjust the font size as needed */
  color: #ffa601; /* Set the color to yellow */
  margin-right: 15px; /* Adjust spacing between social icons as needed */
}

.footer-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; /* Adjust spacing between rows as needed */
}

.footer-column3 {
  display: flex;
  width: 30%;
  align-items: center;
}

.icon-container {
  margin-right: 10px; /* Adjust spacing between icon and text as needed */
  border: 5px solid #ffba05; /* Add a border around the icon */
  padding: 13px 15px; /* Add padding to create space between the icon and the border */
  border-radius: 5px; /* Optional: Add border radius for rounded corners */
}

.phone {
  margin-right: 10px; /* Adjust spacing between icon and text as needed */
  transform: scaleX(-1); /* Flip the icon horizontally */
}

.company-icon {
  color: #ffa601;
}

.icon-text {
  margin-left: 10px; /* Adjust the margin as needed */
}

.footer-column4 {
  width: 30%;
}

.footer-heading {
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-list {
  list-style: none;
  padding: 0;
}

.footer-list li {
  margin-bottom: 5px;
}

.footer-newsletter {
  margin-top: 20px;
}

.newsletter-input-container {
  display: flex;
  align-items: stretch;
  padding-top: 20px;
}

.newsletter-input {
  flex: 1 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
}

.newsletter-button {
  padding: 10px;
  background-color: #ffba04;
  color: #fff;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.footer-divider {
  background-color: #ffba05 !important; /* Set divider color to yellow */
  margin-top: 20px !important; /* Adjust top margin as needed */
  height: 2px; /* Set the height of the divider */
}

.row-divider {
  height: 0.5px;

  margin: 30px 0px;
  border-color: #ffa50061;
  opacity: 0.4;
}

.footer-copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff; /* Set text color to white */
  margin-top: 30px;
}

.footer-links {
  display: flex;
}

.footer-links a {
  color: #fff; /* Set link color to white */
  margin-right: 10px; /* Adjust spacing between links as needed */
}

.footer-links a:last-child {
  margin-right: 0; /* Remove margin from the last link */
}

@media (max-width: 600px) {
  .footer-container {
    flex-direction: column;
    /* width: 70%;
    height: 100%;
    overflow-x: hidden !important; */
  }

  .footer {
    background-color: #24262b;
    padding: 50px 3rem;
    font-size: 16px;
  }

  .footer-column1,
  .footer-column2,
  .footer-column3,
  .footer-column4 {
    width: 100%;
    margin-bottom: 20px;
  }

  .row-divider {
    margin: 20px 0;
  }

  .footer-row {
    display: flex;
    flex-direction: column; /* Change to a column layout for mobile */
    width: 100%;
  }

  .footer-copyright {
    display: flex; /* Use flexbox */
    flex-direction: column; /* Arrange items in a column */
    margin-bottom: 20px; /* Add spacing between sections */
  }

  .copyright-text,
  .footer-links {
    margin-bottom: 20px; /* Add spacing between sections */
  }

  .copyright-text {
    text-align: center; /* Center-align the text */
  }
}

body {
  line-height: 1.5;
  font-family: 'Montserrat,sans-serif,Helvetica Neue,Arial', sans-serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  max-width: 1550px;
  margin: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
ul {
  list-style: none;
}
/* .footer {
  background-color: #24262b;
  padding: 70px 0;
}
.footer-col {
  width: 22%;
  padding: 0 15px;
}
.footer-col h4 {
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}
.footer-col h4::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #ff9d1d;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}
.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footer-col ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
  color: rgb(208, 208, 208);
  display: block;
  transition: all 0.3s ease;
}
.footer-col ul li a:hover {
  color: #ffffff;
  padding-left: 8px;
}
.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: RGB(255, 157, 29, 0.8);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}
.footer-col .social-links a:hover {
  color: #24262b;
  background-color: #ffffff;
}
.logo-Image {
  width: 34%;
}

.logo {
  width: 300px;
}

.columndiv {
  width: 22%;
}

.phargraph {
  padding: 0 10px;
  margin-top: 10px;
  font-size: 16px;
  color: rgb(208, 208, 208);
  font-family: 'Montserrat,sans-serif,Helvetica Neue,Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43rem;
  width: 90%;
  justify-content: center;
  text-align: left;
  letter-spacing: 1px;
}

@media (max-width: 767) {
  .phargraph {
    padding: 0 10px;
    margin-top: 10px;
    font-size: 16px;
    color: rgb(208, 208, 208);
    font-family: 'Montserrat,sans-serif,Helvetica Neue,Arial', sans-serif;
    font-weight: 400;
    line-height: 1.43rem;
    width: 100%;
    justify-content: center;
    text-align: left;
    letter-spacing: 1px;
  }
} */

/* @media (max-width: 767px) {
  .logo-Image {
    width: 100%;
    margin-bottom: 30px;
  }

  .footer {
    background-color: #24262b;
    padding: 70px 0 0 0;
  }
} */

/*responsive*/
/* @media (max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
} */
/* @media (max-width: 574px) {
  .footer-col {
    width: 100%;
  }
} */

.footer {
  background-color: #24262b;
  padding: 50px 5rem;
  font-size: 16px;
}

footer h4 {
  font-size: 20px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
}

.footer-column1 {
  width: 20%;
}

.footer-column2 {
  width: 70%;
}
.h4 {
  margin-bottom: 1rem;
}

.logo {
  width: 250px; /* Adjust logo size as needed */
  height: auto;
  margin-bottom: 20px;
  margin-left: -30px;
}

.content-description {
  font-size: 14px; /* Adjust font size as needed */
  color: #fff; /* Set your desired text color */
  font-size: 16px;
}

.social-links {
  margin-top: 20px;
}

.social-links a {
  font-size: 24px; /* Adjust the font size as needed */
  color: #ffa601; /* Set the color to yellow */
  margin-right: 15px; /* Adjust spacing between social icons as needed */
}

.footer-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; /* Adjust spacing between rows as needed */
}

.footer-column3 {
  display: flex;
  width: 30%;
  align-items: center;
}

.icon-container {
  margin-right: 10px; /* Adjust spacing between icon and text as needed */
  border: 5px solid #ffba05; /* Add a border around the icon */
  padding: 13px 15px; /* Add padding to create space between the icon and the border */
  border-radius: 5px; /* Optional: Add border radius for rounded corners */
}

.phone {
  margin-right: 10px; /* Adjust spacing between icon and text as needed */
  transform: scaleX(-1); /* Flip the icon horizontally */
}

.company-icon {
  color: #ffa601;
}

.icon-text {
  margin-left: 10px; /* Adjust the margin as needed */
}

.footer-column4 {
  width: 30%;
}

.footer-heading {
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-list {
  list-style: none;
  padding: 0;
}

.footer-list li {
  margin-bottom: 5px;
}

.footer-newsletter {
  margin-top: 20px;
}

.newsletter-input-container {
  display: flex;
  align-items: stretch;
  padding-top: 20px;
}

.newsletter-input {
  flex: 1 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
}

.newsletter-button {
  padding: 10px;
  background-color: #ffba04;
  color: #fff;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.footer-divider {
  background-color: #ffba05 !important; /* Set divider color to yellow */
  margin-top: 20px !important; /* Adjust top margin as needed */
  height: 2px; /* Set the height of the divider */
}

.row-divider {
  height: 0.5px;

  margin: 30px 0px;
  border-color: #ffa50061;
  opacity: 0.4;
}

.footer-copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff; /* Set text color to white */
  margin-top: 30px;
}

.footer-links {
  display: flex;
}

.footer-links a {
  color: #fff; /* Set link color to white */
  margin-right: 10px; /* Adjust spacing between links as needed */
}

.footer-links a:last-child {
  margin-right: 0; /* Remove margin from the last link */
}

@media (max-width: 600px) {
  .footer-container {
    flex-direction: column;
    /* width: 70%;
    height: 100%;
    overflow-x: hidden !important; */
  }

  .footer {
    background-color: #24262b;
    padding: 50px 1.5rem;
    padding-bottom: 0rem;
    font-size: 16px;
  }

  .h4 {
    margin-bottom: 1rem;
  }

  .footer-column1,
  .footer-column2,
  .footer-column3,
  .footer-column4 {
    width: 100%;
    margin-bottom: 20px;
  }

  .row-divider {
    margin: 20px 0;
  }

  .footer-row {
    display: flex;
    flex-direction: column; /* Change to a column layout for mobile */
    width: 100%;
  }

  .footer-copyright {
    display: flex; /* Use flexbox */
    flex-direction: column; /* Arrange items in a column */
    margin-bottom: 20px; /* Add spacing between sections */
  }

  .copyright-text,
  .footer-links {
    margin-bottom: 20px; /* Add spacing between sections */
  }

  .copyright-text {
    text-align: center; /* Center-align the text */
  }
}

